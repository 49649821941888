import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/product-widgets/customize';

function get(params) {
  return storeRequest.get(path, {
    params: { ...params },
  });
}
function getAll(params) {
  return storeRequest.get(`/product-widgets`, {
    params: { ...params },
  });
}
function create(data) {
  return storeRequest.post(path, data);
}
function createAll(data) {
  return storeRequest.post(`/product-widgets/public/initial`, data);
}
function createAllAfterCreateStore(data, storeId, storeDomain) {
  storeRequest.setStoreId(storeId);
  storeRequest.setBaseURL('https://' + storeDomain + '/api/');
  return storeRequest.post(`/product-widgets/public/initial`, data);
}
function getById(id) {
  return storeRequest.get(path + id);
}

function update(id, data) {
  return storeRequest.put(path + id, {
    offer: data,
  });
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}
function remove(id) {
  return storeRequest.delete(path + id);
}

function changeActive(action, ids) {
  return storeRequest.patch('/offers', {
    action: action,
    offerIds: ids,
  });
}
//Lấy 1 widget

export const widgetApi = {
  get,
  create,
  update,
  deleteById,
  getById,
  remove,
  changeActive,
  getAll,
  createAll,
  createAllAfterCreateStore,
};
