<template>
  <div :class="`text-${data.align} mt-3`">
    <span
      :style="
        `color: ${data.fontColor}; font-size:${data.fontSize};` +
          (data.bold ? 'font-weight: bold;' : '') +
          (data.underline ? 'text-decoration: underline;' : '') +
          (data.italic ? ' font-style: italic;' : '')
      "
    >
      {{ data.textTitle }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
